import React, { ReactElement } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "./seo";

const stars: ReactElement[] = [];
for (let i = 0; i < 50; i++) {
  stars.push(<div className="star" key={`star-${i}`}></div>);
}

const Landing = () => {
  return (
    <>
      <SEO />
      <div className="lightning" />
      <div className="stars">{stars}</div>
      <div className="h-screen" style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          layout="fullWidth"
          aspectRatio={16 / 10}
          alt=""
          src={"../assets/images/bifrost.webp"}
          formats={["auto", "webp", "avif"]}
          transformOptions={{ fit: "cover" }}
        />
        <div
          className="pb-40"
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="text-center z-10">
            <h1 className="fixed top-12 left-0 right-0 fade-in-slow text-4xl sm:text-7xl tracking-widest text-yellow-400 underline underline-offset-8">
              Asgardian Bulls Club
            </h1>

            <div className="norse-bold fade-in-slower p-5 items-center text-center text-white text-2xl sm:text-5xl">
              <p>Are you prepared to cross the Bifrost and join the Asgardians?</p>
              <div className="pt-10">
                <a href="/home">
                  <button type="button" className="p-5 pt-6 neon-sky rounded-lg text-2xl sm:text-3xl norse-bold">
                    Enter Asgard
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
