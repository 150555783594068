import React from "react";
import Landing from "./components/landing";

const AsgardianBullsClub = () => {
  return (
    <div>
      <Landing />
    </div>
  );
};

export default AsgardianBullsClub;
